<template>
  <div class="home">
    <div class="row">
      <div class="col-md-12 grid-margin">
        <div class="row">
          <div class="col-12 col-xl-8 mb-4 mb-xl-0">
            <h3 class="font-weight-bold white">Bem vindo ao Elementista</h3>
            <h6 class="font-weight-normal mb-0 white">Nossa missão é insprar <span class="orange">a próxima
                geração</span>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card rounded-card">
          <div v-if="feedNoticia1">
            <a :href="feedNoticia1.url" target="_blank" style="text-decoration: none; color: inherit;">
              <div class="card rounded-card" style="background-color: #A4D233;">
                <img :src="feedNoticia1.link" class="card-img-top" alt="...">
                <div style="background-color: #A4D233; min-height: 8rem; max-height: 14rem;" class="card-body rounded-card">
                  <p class="card-text truncated-text" v-html="sanitizeHtml(feedNoticia1.texto)"></p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card rounded-card">
          <div v-if="feedNoticia2">
            <a :href="feedNoticia2.url" target="_blank" style="text-decoration: none; color: inherit;">
              <div class="card rounded-card" style="background-color: #A4D233;">
                <img :src="feedNoticia2.link" class="card-img-top" alt="...">
                <div style="background-color: #A4D233; min-height: 8rem;max-height: 14rem;" class="card-body rounded-card">
                  <p class="card-text truncated-text" v-html="sanitizeHtml(feedNoticia2.texto)"></p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card rounded-card">
          <div v-if="feedNoticia3">
            <a :href="feedNoticia3.url" target="_blank" style="text-decoration: none; color: inherit;">
              <div class="card rounded-card" style="background-color: #A4D233;">
                <img :src="feedNoticia3.link" class="card-img-top" alt="...">
                <div style="background-color: #A4D233; min-height: 8rem;max-height: 14rem;" class="card-body rounded-card ">
                  <p class="card-text truncated-text" v-html="sanitizeHtml(feedNoticia3.texto)"></p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div v-if="feedNoticia5" class="col-md-6 " :key>
        <a :href="feedNoticia5.url" target="_blank" style="text-decoration: none; color: inherit;">
          <div class="card rounded-card" style="background-color: #A4D233;">
            <img :src="feedNoticia3.link" class="card-img-top" alt="...">
            <div class="card-body  rounded-card" style="background-color: #A4D233; min-height: 16rem;max-height: 24rem;">
              <p class="font-1 font-weight-500 white">Notícias</p>
              <p class="font-1 font-weight-500 white  " v-html="sanitizeHtml(feedNoticia5.texto)">
              </p>
            </div>
          </div>
        </a>
      </div>
      <div v-if="feedNoticia5" class="col-md-6 " :key>

        <div class="card">
          <div id="player"></div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import FeedImagem1 from "../../public/imgFeed/modelo1.jpg";
import FeedImagem2 from "../../public/imgFeed/modelo2.jpg";
import FeedImagem3 from "../../public/imgFeed/modelo3.jpg";
import homeService from '../views/services/homeService';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import DOMPurify from 'dompurify';

export default {
  name: 'HomeView',
  components: {
    QuillEditor
  },
  data() {
    return {
      img: FeedImagem1,
      img1: FeedImagem2,
      img2: FeedImagem3,
      feedNoticia1: {},
      feedNoticia2: {},
      feedNoticia3: {},
      feedNoticia4: {},
      feedNoticia5: {},
      feedNoticia6: {},
      feedNoticia7: {},
      feedNoticia8: {},
      postNoticia1: {},
      postNoticia2: {},
      postNoticia3: {},
      postNoticia4: {},
      videoPlaylist: [
        "-QzQsScSmzk", // Primeiro vídeo
        "tnCCN99o14A", // Segundo vídeo
        "WV9j1URAMWs", // Terceiro vídeo
        "DoAPvc6Sa3o",
      ],
      currentVideoIndex: 0,
      player: null,
    }

  },
  mounted() {
    // Adiciona o script da API do YouTube dinamicamente
    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";
    script.async = true;
    script.onload = () => {
      window.onYouTubeIframeAPIReady = this.initializePlayer;
    };
    document.head.appendChild(script);
  },
  created() {
    this.obterFeed();
    this.obterPost();
  },
  methods: {
    initializePlayer() {
      // Cria o player do YouTube
      this.player = new YT.Player("player", {
        height: "1000", // Altura fixa
        width: "100%", // Largura responsiva
        videoId: this.videoPlaylist[this.currentVideoIndex],
        playerVars: {
          autoplay: 1,
          modestbranding: 1,
          showinfo: 0,
          rel: 0,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    onPlayerReady(event) {
      // Muta o áudio do player
      event.target.mute();
    },
    // onYouTubeIframeAPIReady() {
    //   this.player = new YT.Player("player", {
    //     height: "1000",
    //     width: "auto",
    //     videoId: this.videoPlaylist[this.currentVideoIndex],
    //     playerVars: {
    //       autoplay: 1,
    //       modestbranding: 1,
    //       showinfo: 0,
    //       rel: 0,
    //     },
    //     events: {
    //       onStateChange: this.onPlayerStateChange,
    //     },
    //   });
    // },
    onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.ENDED) {
        this.currentVideoIndex++;
        if (this.currentVideoIndex < this.videoPlaylist.length) {
          this.player.loadVideoById(this.videoPlaylist[this.currentVideoIndex]);
        } else {
          console.log("Todos os vídeos foram reproduzidos.");
        }
      }
    },
    sanitizeHtml(html) {
      return DOMPurify.sanitize(html);
    },
    async obterFeed() {
      const dados = await homeService.obterFeed()
      ////console.log("obterFeed", dados);
      const feed1 = dados
        .filter(feed => feed.id_ordem === 1) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          publicada: feed.publicada,
          url: feed.url,
          tag: feed.tag
        }));
      this.feedNoticia1 = feed1[0]
      const feed2 = dados
        .filter(feed => feed.id_ordem === 2) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          url: feed.url,
          publicada: feed.publicada,
          tag: feed.tag
        }));

      this.feedNoticia2 = feed2[0]
      const feed3 = dados
        .filter(feed => feed.id_ordem === 3) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          url: feed.url,
          dt_criacao: feed.dt_criacao,
          publicada: feed.publicada,
          tag: feed.tag
        }));

      this.feedNoticia3 = feed3[0]
      const feed4 = dados
        .filter(feed => feed.id_ordem === 4) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          url: feed.url,
          publicada: feed.publicada,
          tag: feed.tag
        }));
      this.feedNoticia4 = feed4[0]
      const feed5 = dados
        .filter(feed => feed.id_ordem === 5) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          url: feed.url,
          publicada: feed.publicada,
          tag: feed.tag
        }));
      this.feedNoticia5 = feed5[0]
      const feed6 = dados
        .filter(feed => feed.id_ordem === 4) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          url: feed.url,
          publicada: feed.publicada,
          tag: feed.tag
        }));
      this.feedNoticia6 = feed6[0]
      const feed7 = dados
        .filter(feed => feed.id_ordem === 7) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          url: feed.url,
          publicada: feed.publicada,
          tag: feed.tag
        }));
      this.feedNoticia7 = feed7[0]
      const feed8 = dados
        .filter(feed => feed.id_ordem === 8) // Filtra os feeds com id_ordem igual a 1
        .map(feed => ({
          id_atividade: feed.id_atividade,
          id_ordem: feed.id_ordem,
          usuario: feed.usuario,
          titulo: feed.titulo,
          texto: feed.texto,
          link: `https://apienerge.apololab.net/atividades/${feed.imageName}`,
          imageName: feed.imageName,
          dt_criacao: feed.dt_criacao,
          url: feed.url,
          publicada: feed.publicada,
          tag: feed.tag
        }));
      this.feedNoticia8 = feed8[0]
    },
    async obterPost() {
      const dados = await homeService.obterPost()
      //console.log("obter post", dados);
      const post1 = dados
        .filter(post => post.id_ordem === 1) // Filtra os feeds com id_ordem igual a 1
        .map(post => ({
          id_atividade: post.id_atividade,
          id_ordem: post.id_ordem,
          usuario: post.usuario,
          titulo: post.titulo,
          texto: post.texto,
          dt_criacao: post.dt_criacao,
          publicada: post.publicada,
          tag: post.tag,
          url: post.url
        }));
      this.postNoticia1 = post1[0]
      const post2 = dados
        .filter(post => post.id_ordem === 2) // Filtra os feeds com id_ordem igual a 1
        .map(post => ({
          id_atividade: post.id_atividade,
          id_ordem: post.id_ordem,
          usuario: post.usuario,
          titulo: post.titulo,
          texto: post.texto,
          dt_criacao: post.dt_criacao,
          publicada: post.publicada,
          tag: post.tag
        }));
      this.postNoticia2 = post2[0]

      const post3 = dados
        .filter(post => post.id_ordem === 3) // Filtra os feeds com id_ordem igual a 1
        .map(post => ({
          id_atividade: post.id_atividade,
          id_ordem: post.id_ordem,
          usuario: post.usuario,
          titulo: post.titulo,
          texto: post.texto,
          dt_criacao: post.dt_criacao,
          publicada: post.publicada,
          tag: post.tag
        }));
      this.postNoticia3 = post3[0]
      const post4 = dados
        .filter(post => post.id_ordem === 4) // Filtra os feeds com id_ordem igual a 1
        .map(post => ({
          id_atividade: post.id_atividade,
          id_ordem: post.id_ordem,
          usuario: post.usuario,
          titulo: post.titulo,
          texto: post.texto,
          dt_criacao: post.dt_criacao,
          publicada: post.publicada,
          tag: post.tag
        }));
      this.postNoticia4 = post4[0]
    }
  }
}
</script>


<style>
 .rounded-card {
    border-radius: 15px;
  }

  .rounded-card img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
.card-text {
  color: white;

}

.margem-card {
  border-radius: 15%;
}

#player {
  min-height: 26rem;
  max-height: 39rem;
  width: 100%;
  /* Ajusta automaticamente à largura do container */
  border-radius: 1%;
}

.truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Número de linhas que deseja mostrar */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>