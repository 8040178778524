<template>
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <a><router-link class="navbar-brand brand-logo " style="width: auto;" to="/"><img src="img/elementista.png"
            class="mr-3" alt="logo" /></router-link></a>
      <a class="navbar-brand brand-logo-mini" to="/" @click="closeMenu"><img src="img/elementista_mini.png"
            alt="logo" /></a>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
        <span class="icon-menu"></span>
      </button>

      <ul class="navbar-nav navbar-nav-right">

        <li class="nav-item nav-profile dropdown">
          <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
            <div class="user-initials" v-if="initials">{{ initials }}</div>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
            <a class="dropdown-item">
              <i class="ti-settings text-primary"></i>
              Configurações
            </a>
            <a><router-link to="/gestaoUsuarios" class="dropdown-item">
                <i class="mdi mdi-account-settings text-primary"></i>
                Gestão de Usuarios
              </router-link>
            </a>
            <a class="dropdown-item d-flex align-items-center py-2" @click="logout()"> <i
                class="ti-power-off text-primary"></i>Sair</a>
          </div>
        </li>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
        data-toggle="offcanvas">
        <span class="icon-menu"></span>
      </button>
    </div>
  </nav>

</template>

<script>

export default {
  name: 'NavBar',
  data() {
    return {
      usuario: {},
      initials: ''
    }
  },
  mounted() {
    this.user = sessionStorage.getItem('user');
    this.initials = this.generateInitials(this.user);
  },
  methods: {
    generateInitials(name) {
      if (!name) return '';
      const names = name.split(' ');
      const initials = names.map(n => n.charAt(0).toUpperCase()).slice(0, 2).join('');
      return initials;
    },
    closeMenu() {
      const menu = document.querySelector('.navbar-menu-wrapper');
      if (menu) {
        menu.classList.remove('show'); // Fecha o menu caso esteja aberto
      }
    },
    logout() {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
      this.$router.push('/login');
    }
  }
}


</script>
<style scoped>
.user-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #FA8E10;
  color: #ffffff;
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}
</style>