const axios = require('axios');
const store = require('../store/index').default;

const apiClient = axios.create({
  baseURL: 'https://apienerge.apololab.net:5000',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  async config => {
       
   // const token = store.getters.usuario.token; // Obtém o token do store
   const token =   sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

module.exports = apiClient;
