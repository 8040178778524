import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import HomeView from '../views/HomeView.vue'
import auth from '../validador/TokenValido'; // Substitua pelo caminho correto para o arquivo auth.js

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import(/* webpackChunkName: "perfil" */ '../views/PerfilView.vue')
  },
  {
    path: '/videos',
    name: 'videos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/conteudo/Videos.vue')
  },
  {
    path: '/atividades',
    name: 'atividades',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/conteudo/Atividades.vue')
  },
  {
    path: '/cria-atividade',
    name: 'criaAtividade',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/conteudo/criaConteudo/CriaAtividade.vue')
  },
  
  {
    path: '/ver-atividades',
    name: 'VerConteudo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/conteudo/VerMaisConteudo/Verconteudo.vue')
  },
  {
    path: '/ver-videos',
    name: 'VerVideos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/conteudo/VerMaisConteudo/VerVideos.vue')
  },
  ,
  {
    path: '/ver-tutoriais',
    name: 'VerTutoriais',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/conteudo/VerMaisConteudo/VerTutoriais.vue')
  },
  {
    path: '/cria-videos',
    name: 'CriaVideos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/conteudo/criaConteudo/CriaVideos.vue')
  },
  {
    path: '/cria-tutorial',
    name: 'CriaViTutorial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/conteudo/criaConteudo/CriaTutorial.vue')
  },
  {
    path: '/editar-atividade',
    name: 'EditarAtividade',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/conteudo/EditarConteudo/ModalEditarAtividade.vue')
  },
  {
    path: '/editar-videos',
    name: 'EditarVideos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/conteudo/EditarConteudo/ModalEditarVideos.vue')
  },
  {
    path: '/editar-tutorial',
    name: 'EditarTutorial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/conteudo/EditarConteudo/ModalEditarTutorial.vue')
  },
  {
    path: '/tutoriais',
    name: 'tutoriais',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/conteudo/Tutoriais.vue')
  },
  {
    path: '/agua',
    name: 'agua',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laboratorio/Agua.vue')
  },
  ,
  {
    path: '/cria-agua',
    name: 'CriaAgua',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laboratorio/criaConteudo/CriaAgua.vue')
  },
  {
    path: '/editar-agua',
    name: 'EditarAgua',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/EditarLaboratorio/ModalEditarAgua.vue')
  },
  {
    path: '/ver-agua',
    name: 'VerAgua',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/VerMaisLaboratorio/VerAgua.vue')
  },
  {
    path: '/ar',
    name: 'ar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laboratorio/Ar.vue')
  },
  {
    path: '/cria-ar',
    name: 'Cria-ar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laboratorio/criaConteudo/CriaAr.vue')
  },
  {
    path: '/editar-ar',
    name: 'Editar-ar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/EditarLaboratorio/ModalEditarAr.vue')
  },
  {
    path: '/ver-ar',
    name: 'VerAr',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/VerMaisLaboratorio/VerAr.vue')
  },
  {
    path: '/terra',
    name: 'terra',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laboratorio/Terra.vue')
  },

  {
    path: '/ver-terra',
    name: 'VerTerra',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/VerMaisLaboratorio/VerTerra.vue')
  },
  {
    path: '/cria-terra',
    name: 'Cria-terra',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laboratorio/criaConteudo/CriaTerra.vue')
  },
  {
    path: '/editar-terra',
    name: 'Editar-terra',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/EditarLaboratorio/ModalEditarTerra.vue')
  },
  {
    path: '/fogo',
    name: 'fogo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laboratorio/Fogo.vue')
  },
  {
    path: '/ver-fogo',
    name: 'VerFogo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/VerMaisLaboratorio/VerFogo.vue')
  },
  {
    path: '/cria-fogo',
    name: 'Cria-fogo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/laboratorio/criaConteudo/CriaFogo.vue')
  },
  {
    path: '/editar-fogo',
    name: 'editar-fogo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/EditarLaboratorio/ModalEditarFogo.vue')
  },
  {
    path: '/editar-terra',
    name: 'Editar-terra',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/laboratorio/EditarLaboratorio/ModalEditarTerra.vue')
  },
  {
    path: '/noticias',
    name: 'noticias',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/postagem/Noticias.vue')
  },
  {
    path: '/criar-feed',
    name: 'CriarFeed',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/postagem/CriarFeed.vue')
  },
  {
    path: '/criar-post',
    name: 'CriarPost',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/postagem/CriarPost.vue')
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import(/* webpackChunkName: "about" */'../views/RedirectView.vue')
  },
  {
    path: '/gestaoUsuarios',
    name: 'GestaoUsuarios',
    component: () => import(/* webpackChunkName: "gestaoUsuarios" */ '../views/GestaoUsuarios.vue')
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import(/* webpackChunkName: "gestaoUsuarios" */ '../views/Verification.vue')
  }
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});



router.beforeEach(async (to, from, next) => {
  try {
    // Supondo que você tenha uma ação que carrega o usuário
   //  await store.dispatch('usuario'); // Ajuste conforme necessário
    const token =   sessionStorage.getItem('token');
    //console.log("token", token);
    let autH = await auth.verificarToken(token);
    if (autH === 'jwt expired' && to.name !== 'verification') {
      autH = false;
    }
    if (autH === 'jwt malformed' && to.name !== 'verification') {
      autH = false;
    }
    const isAuthenticated = token && autH;
    if (to.name !== 'login' && !isAuthenticated && to.name !== 'verification') {
      next('/login'); // Redireciona para a página de login se o usuário não estiver autenticado e não estiver na página de login
    } else {
      next(); // Continua a navegação normalmente
    }
  } catch (error) {
    console.error('Error during navigation guard:', error);
    next('/login'); // Redireciona para a página de login em caso de erro
  }
});


export default router;
