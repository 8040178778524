<template>
  <div v-if="showNavAndSidebar">
    <Nav v-if="showNavAndSidebar" />
    <div class="container-fluid page-body-wrapper">
      <SidBar />
      <div class="main-panel">
        <div class="content-wrapper">
          <router-view />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Nav from '@/components/navBar/NavBar.vue';
import SidBar from '@/components/navBar/SideBar.vue';

export default {
  components: {
    Nav,
    SidBar,
  },
  setup() {
    const route = useRoute();
    const showNavAndSidebar = computed(() => route.name !== 'login' && route.name !== 'verification');

    return {
      showNavAndSidebar,
    };
  },
};
</script>